import { assertStringNotEmpty, StringNotEmpty } from "./string-not-empty";

export type Uuid = string & StringNotEmpty & { __iq_type_uuid: never };

export function isUuid(value: Uuid | string | unknown): value is Uuid {
  return (
    typeof value === "string" &&
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
      value
    )
  );
}

export function assertUuid(
  value: Uuid | string | unknown
): asserts value is Uuid {
  if (!isUuid(value)) {
    assertStringNotEmpty(value);
    throw new Error("Expected uuid string, got '" + value + "'.");
  }
}

export function validUuid(value: Uuid | string | unknown): Uuid {
  assertUuid(value);
  return value;
}
