import { ErrorHandler, Injectable } from "@angular/core";
import { ManagementService } from "./api/management.service";
import { MyActiveToast, ToastButtonType, ToastService } from "./toast.service";
import { Translation, TranslationsService } from "./translations.service";
import { ErrorNormalizerService, IqError } from "./error-normalizer.service";
import { ErrorTraceService } from "./error-trace.service";
import { SupportService } from "./support.service";
import { DebugModeRepositoryService } from "./repository/debug-mode-repository.service";
import { firstValueFrom } from "rxjs";
import { RequestTraceService } from "./request-trace.service";
import { IqErrorId } from "../_types/iq-error-id";

export interface NormalizedErrorObj {
  isUserError: boolean;
  message: string | Translation;
  id: IqErrorId | null;
  type: string | null;
  payload: {} | null;
  description: string | Translation | null;
  url: string | null;
}

@Injectable({
  providedIn: "root",
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    private _toastService: ToastService,
    private _managementService: ManagementService,
    private _errorNormalizerService: ErrorNormalizerService,
    private _errorTraceService: ErrorTraceService,
    private _requestTraceService: RequestTraceService,
    private _supportService: SupportService,
    private _translationsService: TranslationsService,
    private _debugModeRepositoryService: DebugModeRepositoryService
  ) {}

  public async handle(originalError: any): Promise<void> {
    console.debug("ErrorHandlerService", originalError);

    const error: Error | IqError = await this._errorNormalizerService.normalize(
      originalError
    );

    const normalized = await this.normalize(error);

    console.debug(
      "ErrorHandlerService: normalized",
      normalized.id,
      originalError,
      error,
      normalized
    );

    const danger =
      error instanceof Error ||
      (error.httpStatus && error.httpStatus >= 500 && error.httpStatus <= 599);

    const buttons: ToastButtonType[] = [];
    if (this._supportService.isAvailable()) {
      buttons.push(
        danger
          ? {
              action: "help",
              label: new Translation("error_handler.button.contact_support"),
            }
          : { action: "help", icon: "question_mark" }
      );
    }
    if (normalized.id && this._debugModeRepositoryService.current) {
      buttons.push({ action: "debug", icon: "bug_report" });
    }

    const toast: MyActiveToast = this._toastService.show({
      custom: {
        type: danger ? "danger" : "warning",
        message: normalized.description
          ? normalized.description
          : normalized.message,
        title: normalized.description ? undefined : normalized.message,
        iqErrorId: normalized.id ?? undefined,
        buttons: buttons,
      },
      disableTimeOut: true,
    });

    toast.onAction.subscribe(async (action) => {
      if (action === "help") {
        await this._supportService.openFor(error).catch(() => {
          this._toastService.warning(
            new Translation("error_handler.support_not_available.title")
          );
        });
      } else if (
        action === "debug" &&
        normalized.id &&
        this._debugModeRepositoryService.current
      ) {
        window.open("/admin/errors?id=" + normalized.id, "_blank");
      }
    });
  }

  public async normalize(error: any): Promise<NormalizedErrorObj> {
    return this._normalize(await this._errorNormalizerService.normalize(error));
  }

  public async _normalize(error: Error | IqError): Promise<NormalizedErrorObj> {
    if (error instanceof Error) {
      const loggedError: Promise<IqErrorId> =
        window.location.hostname !== "localhost"
          ? firstValueFrom(
              this._managementService.logJsError(error, {
                prevErrors: this._errorTraceService
                  .getErrors()
                  .map((e) => (typeof e === "string" ? e : e.message)),
                prevRequests: this._requestTraceService.getRequests(),
              })
            )
          : Promise.reject("do not log errors on localhost");
      return await loggedError
        .then((errorId): NormalizedErrorObj => {
          this._errorTraceService.addId(errorId);
          return {
            isUserError: false,
            message: new Translation("error_handler.unknown_with_id.message", {
              id: errorId,
            }),
            description: new Translation(
              "error_handler.unknown_with_id.description",
              {
                id: errorId,
              }
            ),
            id: errorId,
            type: null,
            payload: null,
            url: null,
          };
        })
        .catch((err): NormalizedErrorObj => {
          console.debug("ErrorHandlerService: failed to log error", error, err);
          return {
            isUserError: false,
            message: new Translation("error_handler.unknown.message"),
            description: new Translation("error_handler.unknown.description"),
            id: null,
            type: null,
            payload: null,
            url: null,
          };
        });
    }

    let message: string | Translation;
    let description: string | Translation | null = null;
    if (error.message && error.description) {
      message = error.message;
      description = error.description;
    } else if (error.message || error.description) {
      message = error.message ?? error.description ?? "";
    } else {
      message = new Translation("error_handler.unknown.message");
      description = new Translation("error_handler.unknown.description");
    }

    return {
      isUserError: !!(
        error.httpStatus &&
        error.httpStatus >= 400 &&
        error.httpStatus <= 499
      ),
      message: message,
      description: description,
      id: error.id,
      type: error.type ?? null,
      payload: error.payload ?? null,
      url: error.url ?? null,
    };
  }

  public async normalizeTranslated(error: any): Promise<{
    message: string;
    description: string | null;
    fullMessage: string;
  }> {
    const normalized = await this.normalize(
      await this._errorNormalizerService.normalize(error)
    );

    let message: string =
      (await this._translationsService.resolvePromise(normalized.message)) ??
      "";
    let description: string | null =
      (await this._translationsService.resolvePromise(
        normalized.description
      )) ?? null;

    return {
      message: message,
      description: description,
      fullMessage: ((message ?? "") + " " + (description ?? "")).trim(),
    };
  }

  handleError(error: any): void {
    this.handle(error);
  }
}
