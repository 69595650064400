export class Scope {
  private constructor(private readonly key: string) {}

  public static fromString<T extends string>(key: T): SpecificScope<T> {
    return new Scope(key) as SpecificScope<T>;
  }

  public static createMany(
    scopes: string | Scope | (string | Scope)[]
  ): Scope[] {
    if (!Array.isArray(scopes)) {
      scopes = [scopes];
    }
    return scopes
      .map((s) => (typeof s === "string" ? Scope.fromString(s) : s))
      .filter((s) => s instanceof Scope);
  }

  public toString(): string {
    return this.key;
  }

  public isSpecialScope(): boolean {
    return this.key.split(".").length === 1;
  }

  public isTenantIndependent(): boolean {
    return this.equals(Scopes.TENANT_INDEPENDENT);
  }

  public isAdmin(): boolean {
    return this.equals(Scopes.ALL);
  }

  public isOffline(): boolean {
    return this.equals(Scopes.OFFLINE);
  }

  public equals(other: Scope): boolean {
    return this.key === other.key;
  }

  public allows(other: Scope): boolean {
    if (other.equals(this)) {
      return true;
    }

    if (other.isSpecialScope()) {
      return false;
    }

    if (this.isAdmin()) {
      return true;
    }

    const parts = other.key.split(".");
    for (let i = 1; i < parts.length; i++) {
      const possibleMorePrivilegedScope = parts.slice(0, i).join(".") + ".all";
      if (this.equals(Scope.fromString(possibleMorePrivilegedScope))) {
        return true;
      }
    }

    return false;
  }
}

type SpecificScope<T extends string> = Scope & { toString(): T };

// DO NOT MODIFY THIS COMMENT autogenerated start
export const Scopes: {
  readonly ALL: SpecificScope<"all">;
  readonly TENANT_INDEPENDENT: SpecificScope<"tenant_independent">;
  readonly OFFLINE: SpecificScope<"offline">;
  readonly SECURITY_ALL: SpecificScope<"security.all">;
  readonly SECURITY_CLIENTS_ALL: SpecificScope<"security.clients.all">;
  readonly SECURITY_CLIENTS_SHOW: SpecificScope<"security.clients.show">;
  readonly SECURITY_CLIENTS_CREATE: SpecificScope<"security.clients.create">;
  readonly SECURITY_CLIENTS_UPDATE: SpecificScope<"security.clients.update">;
  readonly SECURITY_CLIENTS_DELETE: SpecificScope<"security.clients.delete">;
  readonly SECURITY_CLIENTS_SHOW_SECRETS: SpecificScope<"security.clients.show_secrets">;
  readonly SECURITY_OAUTH_CLIENTS_ALL: SpecificScope<"security.oauth_clients.all">;
  readonly SECURITY_OAUTH_CLIENTS_SHOW: SpecificScope<"security.oauth_clients.show">;
  readonly SECURITY_OAUTH_CLIENTS_CREATE: SpecificScope<"security.oauth_clients.create">;
  readonly SECURITY_OAUTH_CLIENTS_UPDATE: SpecificScope<"security.oauth_clients.update">;
  readonly SECURITY_OAUTH_CLIENTS_DELETE: SpecificScope<"security.oauth_clients.delete">;
  readonly SECURITY_OAUTH_CLIENTS_SHOW_SECRETS: SpecificScope<"security.oauth_clients.show_secrets">;
  readonly SECURITY_TENANTS_REGISTER: SpecificScope<"security.tenants.register">;
  readonly SECURITY_TENANTS_DELETE: SpecificScope<"security.tenants.delete">;
  readonly SECURITY_USERS_ALL: SpecificScope<"security.users.all">;
  readonly SECURITY_USERS_SHOW: SpecificScope<"security.users.show">;
  readonly SECURITY_USERS_CREATE: SpecificScope<"security.users.create">;
  readonly SECURITY_USERS_UPDATE: SpecificScope<"security.users.update">;
  readonly SECURITY_USERS_DELETE: SpecificScope<"security.users.delete">;
  readonly SECURITY_GROUPS_ALL: SpecificScope<"security.groups.all">;
  readonly SECURITY_GROUPS_SHOW: SpecificScope<"security.groups.show">;
  readonly SECURITY_GROUPS_CREATE: SpecificScope<"security.groups.create">;
  readonly SECURITY_GROUPS_UPDATE: SpecificScope<"security.groups.update">;
  readonly SECURITY_GROUPS_DELETE: SpecificScope<"security.groups.delete">;
  readonly SECURITY_SUBSCRIPTIONS_ALL: SpecificScope<"security.subscriptions.all">;
  readonly SECURITY_SUBSCRIPTIONS_SHOW: SpecificScope<"security.subscriptions.show">;
  readonly SECURITY_SUBSCRIPTIONS_CREATE: SpecificScope<"security.subscriptions.create">;
  readonly SECURITY_SUBSCRIPTIONS_UPDATE: SpecificScope<"security.subscriptions.update">;
  readonly SECURITY_SUBSCRIPTIONS_DELETE: SpecificScope<"security.subscriptions.delete">;
  readonly TENANT_ALL: SpecificScope<"tenant.all">;
  readonly TENANT_CREDENTIALS_ALL: SpecificScope<"tenant.credentials.all">;
  readonly TENANT_CREDENTIALS_SHOW: SpecificScope<"tenant.credentials.show">;
  readonly TENANT_CREDENTIALS_CREATE: SpecificScope<"tenant.credentials.create">;
  readonly TENANT_CREDENTIALS_UPDATE: SpecificScope<"tenant.credentials.update">;
  readonly TENANT_CREDENTIALS_DELETE: SpecificScope<"tenant.credentials.delete">;
  readonly TENANT_CREDENTIALS_SHOW_SECRETS: SpecificScope<"tenant.credentials.show_secrets">;
  readonly TENANT_CREDENTIALS_REFRESH_OAUTH: SpecificScope<"tenant.credentials.refresh_oauth">;
  readonly TENANT_CREDENTIALS_REFRESH_STATUS: SpecificScope<"tenant.credentials.refresh_status">;
  readonly TENANT_DATASOURCES_ALL: SpecificScope<"tenant.datasources.all">;
  readonly TENANT_DATASOURCES_SHOW: SpecificScope<"tenant.datasources.show">;
  readonly TENANT_DATASOURCES_CREATE: SpecificScope<"tenant.datasources.create">;
  readonly TENANT_DATASOURCES_UPDATE: SpecificScope<"tenant.datasources.update">;
  readonly TENANT_DATASOURCES_DELETE: SpecificScope<"tenant.datasources.delete">;
  readonly TENANT_DATASOURCES_ACTIVATE: SpecificScope<"tenant.datasources.activate">;
  readonly TENANT_DATASOURCES_DEACTIVATE: SpecificScope<"tenant.datasources.deactivate">;
  readonly TENANT_DATASOURCES_META_TEMPLATES_SHOW: SpecificScope<"tenant.datasources.meta_templates.show">;
  readonly TENANT_DATASOURCE_TYPES_SHOW: SpecificScope<"tenant.datasource_types.show">;
  readonly TENANT_SHARED_DATASOURCES_ALL: SpecificScope<"tenant.shared_datasources.all">;
  readonly TENANT_SHARED_DATASOURCES_SHOW: SpecificScope<"tenant.shared_datasources.show">;
  readonly TENANT_SHARED_DATASOURCES_CREATE: SpecificScope<"tenant.shared_datasources.create">;
  readonly TENANT_SHARED_DATASOURCES_UPDATE: SpecificScope<"tenant.shared_datasources.update">;
  readonly TENANT_SHARED_DATASOURCES_DELETE: SpecificScope<"tenant.shared_datasources.delete">;
  readonly TENANT_TEMPLATES_ALL: SpecificScope<"tenant.templates.all">;
  readonly TENANT_TEMPLATES_SHOW: SpecificScope<"tenant.templates.show">;
  readonly TENANT_TEMPLATES_CREATE: SpecificScope<"tenant.templates.create">;
  readonly TENANT_TEMPLATES_UPDATE: SpecificScope<"tenant.templates.update">;
  readonly TENANT_TEMPLATES_DELETE: SpecificScope<"tenant.templates.delete">;
  readonly TENANT_DATASOURCE_TEMPLATES_ALL: SpecificScope<"tenant.datasource_templates.all">;
  readonly TENANT_DATASOURCE_TEMPLATES_SHOW: SpecificScope<"tenant.datasource_templates.show">;
  readonly TENANT_DATASOURCE_TEMPLATES_CREATE: SpecificScope<"tenant.datasource_templates.create">;
  readonly TENANT_DATASOURCE_TEMPLATES_UPDATE: SpecificScope<"tenant.datasource_templates.update">;
  readonly TENANT_DATASOURCE_TEMPLATES_DELETE: SpecificScope<"tenant.datasource_templates.delete">;
  readonly TENANT_DATASOURCE_TEMPLATES_ACTIVATE: SpecificScope<"tenant.datasource_templates.activate">;
  readonly TENANT_DATASOURCE_TEMPLATES_DEACTIVATE: SpecificScope<"tenant.datasource_templates.deactivate">;
  readonly TENANT_DATASOURCE_TEMPLATES_NOTIFY_DATA_IMPORTED: SpecificScope<"tenant.datasource_templates.notify_data_imported">;
  readonly TENANT_DATASOURCE_BUCKET_GETFILE: SpecificScope<"tenant.datasource.bucket.getfile">;
  readonly TENANT_DATASOURCE_FTP_USER_SHOW: SpecificScope<"tenant.datasource.ftpuser.show">;
  readonly TENANT_DATASOURCE_FTP_USER_REGENERATE_PASSWORD: SpecificScope<"tenant.datasource.ftpuser.regeneratepassword">;
  readonly TENANT_DATASOURCE_IMPORT_MAIL_SHOW: SpecificScope<"tenant.datasource.import_mail.show">;
  readonly TENANT_DATASOURCE_IMPORT_MAIL_IMPORT: SpecificScope<"tenant.datasource.import_mail.import">;
  readonly TENANT_DATASOURCE_IMPORT_MAIL_REGENERATE_EMAIL: SpecificScope<"tenant.datasource.import_mail.regenerate_email">;
  readonly TENANT_DATASOURCE_NOTIFY_DATA_AVAILABLE: SpecificScope<"tenant.datasource.notify_data_available">;
  readonly TENANT_DATASOURCE_MANUAL_RETRY: SpecificScope<"tenant.datasource.manual_retry">;
  readonly TENANT_TENANTS_ALL: SpecificScope<"tenant.tenants.all">;
  readonly TENANT_TENANTS_SHOW: SpecificScope<"tenant.tenants.show">;
  readonly TENANT_TENANTS_CREATE: SpecificScope<"tenant.tenants.create">;
  readonly TENANT_TENANTS_UPDATE: SpecificScope<"tenant.tenants.update">;
  readonly TENANT_TENANTS_DELETE: SpecificScope<"tenant.tenants.delete">;
  readonly TENANT_TENANTS_ACTIVATE: SpecificScope<"tenant.tenants.activate">;
  readonly TENANT_TENANTS_DEACTIVATE: SpecificScope<"tenant.tenants.deactivate">;
  readonly TENANT_TRANSLATIONS_SHOW: SpecificScope<"tenant.translations.show">;
  readonly TENANT_AUDIT_LOGS_SHOW: SpecificScope<"tenant.audit_logs.show">;
  readonly TENANT_EXPLORE_PRESET_ALL: SpecificScope<"tenant.explore_preset.all">;
  readonly TENANT_EXPLORE_PRESET_SHOW: SpecificScope<"tenant.explore_preset.show">;
  readonly TENANT_EXPLORE_PRESET_CREATE: SpecificScope<"tenant.explore_preset.create">;
  readonly TENANT_EXPLORE_PRESET_UPDATE: SpecificScope<"tenant.explore_preset.update">;
  readonly TENANT_EXPLORE_PRESET_DELETE: SpecificScope<"tenant.explore_preset.delete">;
  readonly TENANT_EXPLORE_PRESET_NOTIFY_USE: SpecificScope<"tenant.explore_preset.notify_use">;
  readonly TENANT_EXPLORE_PRESET_MY_SHOW: SpecificScope<"tenant.explore_preset.my.show">;
  readonly TENANT_EXPLORE_PRESET_MY_CREATE: SpecificScope<"tenant.explore_preset.my.create">;
  readonly TENANT_EXPLORE_PRESET_MY_UPDATE: SpecificScope<"tenant.explore_preset.my.update">;
  readonly TENANT_EXPLORE_PRESET_MY_DELETE: SpecificScope<"tenant.explore_preset.my.delete">;
  readonly TENANT_EXPLORE_PRESET_MY_NOTIFY_USE: SpecificScope<"tenant.explore_preset.my.notify_use">;
  readonly TENANT_DASHBOARDS_ALL: SpecificScope<"tenant.dashboards.all">;
  readonly TENANT_DASHBOARDS_SHOW: SpecificScope<"tenant.dashboards.show">;
  readonly TENANT_REPORTS_ALL: SpecificScope<"tenant.reports.all">;
  readonly TENANT_REPORTS_SHOW: SpecificScope<"tenant.reports.show">;
  readonly TENANT_REPORTS_CREATE: SpecificScope<"tenant.reports.create">;
  readonly TENANT_REPORTS_UPDATE: SpecificScope<"tenant.reports.update">;
  readonly TENANT_REPORTS_DELETE: SpecificScope<"tenant.reports.delete">;
  readonly TENANT_REPORTS_ACTIVATE: SpecificScope<"tenant.reports.activate">;
  readonly TENANT_REPORTS_DEACTIVATE: SpecificScope<"tenant.reports.deactivate">;
  readonly TENANT_CURRENCIES_ALL: SpecificScope<"tenant.currencies.all">;
  readonly TENANT_CURRENCIES_SHOW: SpecificScope<"tenant.currencies.show">;
  readonly NORMALIZATION_ALL: SpecificScope<"normalization.all">;
  readonly NORMALIZATION_REPORTINGS_ALL: SpecificScope<"normalization.reportings.all">;
  readonly NORMALIZATION_REPORTINGS_NORMALIZE: SpecificScope<"normalization.reportings.normalize">;
  readonly NORMALIZATION_REPORTINGS_FETCH: SpecificScope<"normalization.reportings.fetch">;
  readonly COLLECT_ALL: SpecificScope<"collect.all">;
  readonly COLLECT_METRICS_ALL: SpecificScope<"collect.metrics.all">;
  readonly COLLECT_METRICS_SHOW: SpecificScope<"collect.metrics.show">;
  readonly COLLECT_DIMENSIONS_ALL: SpecificScope<"collect.dimensions.all">;
  readonly COLLECT_DIMENSIONS_SHOW: SpecificScope<"collect.dimensions.show">;
  readonly COLLECT_REPORTINGS_ALL: SpecificScope<"collect.reportings.all">;
  readonly COLLECT_REPORTINGS_IMPORT: SpecificScope<"collect.reportings.import">;
  readonly COLLECT_REPORTINGS_DELETE: SpecificScope<"collect.reportings.delete">;
  readonly COLLECT_REPORTINGS_BACKUP: SpecificScope<"collect.reportings.backup">;
  readonly COLLECT_REPORTINGS_SHARED_DATASOURCE_ALL: SpecificScope<"collect.reportings.shared_datasource.all">;
  readonly COLLECT_REPORTINGS_SHARED_DATASOURCE_IMPORT: SpecificScope<"collect.reportings.shared_datasource.import">;
  readonly COLLECT_REPORTINGS_SHARED_DATASOURCE_DELETE: SpecificScope<"collect.reportings.shared_datasource.delete">;
  readonly REPORTINGS_ALL: SpecificScope<"reportings.all">;
  readonly REPORTINGS_CUSTOM_DIMENSIONS_ALL: SpecificScope<"reportings.custom_dimensions.all">;
  readonly REPORTINGS_CUSTOM_DIMENSIONS_SHOW: SpecificScope<"reportings.custom_dimensions.show">;
  readonly REPORTINGS_CUSTOM_DIMENSIONS_CREATE: SpecificScope<"reportings.custom_dimensions.create">;
  readonly REPORTINGS_CUSTOM_DIMENSIONS_UPDATE: SpecificScope<"reportings.custom_dimensions.update">;
  readonly REPORTINGS_CUSTOM_DIMENSIONS_DELETE: SpecificScope<"reportings.custom_dimensions.delete">;
  readonly REPORTINGS_CUSTOM_DIMENSIONS_CHECK: SpecificScope<"reportings.custom_dimensions.check">;
  readonly REPORTINGS_CUSTOM_METRICS_ALL: SpecificScope<"reportings.custom_metrics.all">;
  readonly REPORTINGS_CUSTOM_METRICS_SHOW: SpecificScope<"reportings.custom_metrics.show">;
  readonly REPORTINGS_CUSTOM_METRICS_CREATE: SpecificScope<"reportings.custom_metrics.create">;
  readonly REPORTINGS_CUSTOM_METRICS_UPDATE: SpecificScope<"reportings.custom_metrics.update">;
  readonly REPORTINGS_CUSTOM_METRICS_DELETE: SpecificScope<"reportings.custom_metrics.delete">;
  readonly REPORTINGS_CUSTOM_METRICS_CHECK: SpecificScope<"reportings.custom_metrics.check">;
  readonly REPORTINGS_REPORTING_ALL: SpecificScope<"reportings.reporting.all">;
  readonly REPORTINGS_REPORTING_FIELDS_ALL: SpecificScope<"reportings.reporting.fields.all">;
  readonly REPORTINGS_REPORTING_FIELDS_SHOW: SpecificScope<"reportings.reporting.fields.show">;
  readonly REPORTINGS_REPORTING_DATA_ALL: SpecificScope<"reportings.reporting.data.all">;
  readonly REPORTINGS_REPORTING_DATA_SHOW: SpecificScope<"reportings.reporting.data.show">;
  readonly REPORTINGS_REPORTING_GOOGLE_DATA_STUDIO_ALL: SpecificScope<"reportings.reporting.google_data_studio.all">;
  readonly REPORTINGS_REPORTING_GOOGLE_DATA_STUDIO_SHOW: SpecificScope<"reportings.reporting.google_data_studio.show">;
  readonly REPORTINGS_REPORTING_INITIALIZE_FIELDS: SpecificScope<"reportings.reporting.initialize_fields">;
  readonly REPORTINGS_STATISTICS_SHOW: SpecificScope<"reportings.statistics.show">;
  readonly REPORTINGS_CACHE_CLEAR: SpecificScope<"reportings.cache.clear">;
  readonly REPORTINGS_CACHE_WARMUP: SpecificScope<"reportings.cache.warmup">;
  readonly REPORTINGS_FIELD_METAS_ALL: SpecificScope<"reportings.field_metas.all">;
  readonly REPORTINGS_FIELD_METAS_SHOW: SpecificScope<"reportings.field_metas.show">;
  readonly REPORTINGS_FIELD_METAS_UPDATE: SpecificScope<"reportings.field_metas.update">;
  readonly REPORTINGS_OUTGOING_DATASHARE_ALL: SpecificScope<"reportings.outgoing_datashare.all">;
  readonly REPORTINGS_OUTGOING_DATASHARE_SHOW: SpecificScope<"reportings.outgoing_datashare.show">;
  readonly REPORTINGS_OUTGOING_DATASHARE_CREATE: SpecificScope<"reportings.outgoing_datashare.create">;
  readonly REPORTINGS_OUTGOING_DATASHARE_UPDATE: SpecificScope<"reportings.outgoing_datashare.update">;
  readonly REPORTINGS_OUTGOING_DATASHARE_DELETE: SpecificScope<"reportings.outgoing_datashare.delete">;
  readonly EVENTS_ALL: SpecificScope<"events.all">;
  readonly EVENTS_PUBLISH: SpecificScope<"events.publish">;
  readonly EVENTS_LISTEN: SpecificScope<"events.listen">;
  readonly MANAGEMENT_ALL: SpecificScope<"management.all">;
  readonly MANAGEMENT_CHECK: SpecificScope<"management.check">;
  readonly MANAGEMENT_RUN: SpecificScope<"management.run">;
  readonly MANAGEMENT_IMPORT_JOB_SHOW: SpecificScope<"management.import_job.show">;
  readonly MANAGEMENT_IMPORT_JOB_UPDATE: SpecificScope<"management.import_job.update">;
  readonly MANAGEMENT_ERROR_DEBUG_INFO_SHOW: SpecificScope<"management.error_debug_info_show">;
  readonly CURRENCY_ALL: SpecificScope<"currency.all">;
  readonly CURRENCY_OVERVIEW: SpecificScope<"currency.overview">;
  readonly CURRENCY_RUN: SpecificScope<"currency.run">;
} = {
  ALL: Scope.fromString("all"),
  TENANT_INDEPENDENT: Scope.fromString("tenant_independent"),
  OFFLINE: Scope.fromString("offline"),
  SECURITY_ALL: Scope.fromString("security.all"),
  SECURITY_CLIENTS_ALL: Scope.fromString("security.clients.all"),
  SECURITY_CLIENTS_SHOW: Scope.fromString("security.clients.show"),
  SECURITY_CLIENTS_CREATE: Scope.fromString("security.clients.create"),
  SECURITY_CLIENTS_UPDATE: Scope.fromString("security.clients.update"),
  SECURITY_CLIENTS_DELETE: Scope.fromString("security.clients.delete"),
  SECURITY_CLIENTS_SHOW_SECRETS: Scope.fromString(
    "security.clients.show_secrets"
  ),
  SECURITY_OAUTH_CLIENTS_ALL: Scope.fromString("security.oauth_clients.all"),
  SECURITY_OAUTH_CLIENTS_SHOW: Scope.fromString("security.oauth_clients.show"),
  SECURITY_OAUTH_CLIENTS_CREATE: Scope.fromString(
    "security.oauth_clients.create"
  ),
  SECURITY_OAUTH_CLIENTS_UPDATE: Scope.fromString(
    "security.oauth_clients.update"
  ),
  SECURITY_OAUTH_CLIENTS_DELETE: Scope.fromString(
    "security.oauth_clients.delete"
  ),
  SECURITY_OAUTH_CLIENTS_SHOW_SECRETS: Scope.fromString(
    "security.oauth_clients.show_secrets"
  ),
  SECURITY_TENANTS_REGISTER: Scope.fromString("security.tenants.register"),
  SECURITY_TENANTS_DELETE: Scope.fromString("security.tenants.delete"),
  SECURITY_USERS_ALL: Scope.fromString("security.users.all"),
  SECURITY_USERS_SHOW: Scope.fromString("security.users.show"),
  SECURITY_USERS_CREATE: Scope.fromString("security.users.create"),
  SECURITY_USERS_UPDATE: Scope.fromString("security.users.update"),
  SECURITY_USERS_DELETE: Scope.fromString("security.users.delete"),
  SECURITY_GROUPS_ALL: Scope.fromString("security.groups.all"),
  SECURITY_GROUPS_SHOW: Scope.fromString("security.groups.show"),
  SECURITY_GROUPS_CREATE: Scope.fromString("security.groups.create"),
  SECURITY_GROUPS_UPDATE: Scope.fromString("security.groups.update"),
  SECURITY_GROUPS_DELETE: Scope.fromString("security.groups.delete"),
  SECURITY_SUBSCRIPTIONS_ALL: Scope.fromString("security.subscriptions.all"),
  SECURITY_SUBSCRIPTIONS_SHOW: Scope.fromString("security.subscriptions.show"),
  SECURITY_SUBSCRIPTIONS_CREATE: Scope.fromString(
    "security.subscriptions.create"
  ),
  SECURITY_SUBSCRIPTIONS_UPDATE: Scope.fromString(
    "security.subscriptions.update"
  ),
  SECURITY_SUBSCRIPTIONS_DELETE: Scope.fromString(
    "security.subscriptions.delete"
  ),
  TENANT_ALL: Scope.fromString("tenant.all"),
  TENANT_CREDENTIALS_ALL: Scope.fromString("tenant.credentials.all"),
  TENANT_CREDENTIALS_SHOW: Scope.fromString("tenant.credentials.show"),
  TENANT_CREDENTIALS_CREATE: Scope.fromString("tenant.credentials.create"),
  TENANT_CREDENTIALS_UPDATE: Scope.fromString("tenant.credentials.update"),
  TENANT_CREDENTIALS_DELETE: Scope.fromString("tenant.credentials.delete"),
  TENANT_CREDENTIALS_SHOW_SECRETS: Scope.fromString(
    "tenant.credentials.show_secrets"
  ),
  TENANT_CREDENTIALS_REFRESH_OAUTH: Scope.fromString(
    "tenant.credentials.refresh_oauth"
  ),
  TENANT_CREDENTIALS_REFRESH_STATUS: Scope.fromString(
    "tenant.credentials.refresh_status"
  ),
  TENANT_DATASOURCES_ALL: Scope.fromString("tenant.datasources.all"),
  TENANT_DATASOURCES_SHOW: Scope.fromString("tenant.datasources.show"),
  TENANT_DATASOURCES_CREATE: Scope.fromString("tenant.datasources.create"),
  TENANT_DATASOURCES_UPDATE: Scope.fromString("tenant.datasources.update"),
  TENANT_DATASOURCES_DELETE: Scope.fromString("tenant.datasources.delete"),
  TENANT_DATASOURCES_ACTIVATE: Scope.fromString("tenant.datasources.activate"),
  TENANT_DATASOURCES_DEACTIVATE: Scope.fromString(
    "tenant.datasources.deactivate"
  ),
  TENANT_DATASOURCES_META_TEMPLATES_SHOW: Scope.fromString(
    "tenant.datasources.meta_templates.show"
  ),
  TENANT_DATASOURCE_TYPES_SHOW: Scope.fromString(
    "tenant.datasource_types.show"
  ),
  TENANT_SHARED_DATASOURCES_ALL: Scope.fromString(
    "tenant.shared_datasources.all"
  ),
  TENANT_SHARED_DATASOURCES_SHOW: Scope.fromString(
    "tenant.shared_datasources.show"
  ),
  TENANT_SHARED_DATASOURCES_CREATE: Scope.fromString(
    "tenant.shared_datasources.create"
  ),
  TENANT_SHARED_DATASOURCES_UPDATE: Scope.fromString(
    "tenant.shared_datasources.update"
  ),
  TENANT_SHARED_DATASOURCES_DELETE: Scope.fromString(
    "tenant.shared_datasources.delete"
  ),
  TENANT_TEMPLATES_ALL: Scope.fromString("tenant.templates.all"),
  TENANT_TEMPLATES_SHOW: Scope.fromString("tenant.templates.show"),
  TENANT_TEMPLATES_CREATE: Scope.fromString("tenant.templates.create"),
  TENANT_TEMPLATES_UPDATE: Scope.fromString("tenant.templates.update"),
  TENANT_TEMPLATES_DELETE: Scope.fromString("tenant.templates.delete"),
  TENANT_DATASOURCE_TEMPLATES_ALL: Scope.fromString(
    "tenant.datasource_templates.all"
  ),
  TENANT_DATASOURCE_TEMPLATES_SHOW: Scope.fromString(
    "tenant.datasource_templates.show"
  ),
  TENANT_DATASOURCE_TEMPLATES_CREATE: Scope.fromString(
    "tenant.datasource_templates.create"
  ),
  TENANT_DATASOURCE_TEMPLATES_UPDATE: Scope.fromString(
    "tenant.datasource_templates.update"
  ),
  TENANT_DATASOURCE_TEMPLATES_DELETE: Scope.fromString(
    "tenant.datasource_templates.delete"
  ),
  TENANT_DATASOURCE_TEMPLATES_ACTIVATE: Scope.fromString(
    "tenant.datasource_templates.activate"
  ),
  TENANT_DATASOURCE_TEMPLATES_DEACTIVATE: Scope.fromString(
    "tenant.datasource_templates.deactivate"
  ),
  TENANT_DATASOURCE_TEMPLATES_NOTIFY_DATA_IMPORTED: Scope.fromString(
    "tenant.datasource_templates.notify_data_imported"
  ),
  TENANT_DATASOURCE_BUCKET_GETFILE: Scope.fromString(
    "tenant.datasource.bucket.getfile"
  ),
  TENANT_DATASOURCE_FTP_USER_SHOW: Scope.fromString(
    "tenant.datasource.ftpuser.show"
  ),
  TENANT_DATASOURCE_FTP_USER_REGENERATE_PASSWORD: Scope.fromString(
    "tenant.datasource.ftpuser.regeneratepassword"
  ),
  TENANT_DATASOURCE_IMPORT_MAIL_SHOW: Scope.fromString(
    "tenant.datasource.import_mail.show"
  ),
  TENANT_DATASOURCE_IMPORT_MAIL_IMPORT: Scope.fromString(
    "tenant.datasource.import_mail.import"
  ),
  TENANT_DATASOURCE_IMPORT_MAIL_REGENERATE_EMAIL: Scope.fromString(
    "tenant.datasource.import_mail.regenerate_email"
  ),
  TENANT_DATASOURCE_NOTIFY_DATA_AVAILABLE: Scope.fromString(
    "tenant.datasource.notify_data_available"
  ),
  TENANT_DATASOURCE_MANUAL_RETRY: Scope.fromString(
    "tenant.datasource.manual_retry"
  ),
  TENANT_TENANTS_ALL: Scope.fromString("tenant.tenants.all"),
  TENANT_TENANTS_SHOW: Scope.fromString("tenant.tenants.show"),
  TENANT_TENANTS_CREATE: Scope.fromString("tenant.tenants.create"),
  TENANT_TENANTS_UPDATE: Scope.fromString("tenant.tenants.update"),
  TENANT_TENANTS_DELETE: Scope.fromString("tenant.tenants.delete"),
  TENANT_TENANTS_ACTIVATE: Scope.fromString("tenant.tenants.activate"),
  TENANT_TENANTS_DEACTIVATE: Scope.fromString("tenant.tenants.deactivate"),
  TENANT_TRANSLATIONS_SHOW: Scope.fromString("tenant.translations.show"),
  TENANT_AUDIT_LOGS_SHOW: Scope.fromString("tenant.audit_logs.show"),
  TENANT_EXPLORE_PRESET_ALL: Scope.fromString("tenant.explore_preset.all"),
  TENANT_EXPLORE_PRESET_SHOW: Scope.fromString("tenant.explore_preset.show"),
  TENANT_EXPLORE_PRESET_CREATE: Scope.fromString(
    "tenant.explore_preset.create"
  ),
  TENANT_EXPLORE_PRESET_UPDATE: Scope.fromString(
    "tenant.explore_preset.update"
  ),
  TENANT_EXPLORE_PRESET_DELETE: Scope.fromString(
    "tenant.explore_preset.delete"
  ),
  TENANT_EXPLORE_PRESET_NOTIFY_USE: Scope.fromString(
    "tenant.explore_preset.notify_use"
  ),
  TENANT_EXPLORE_PRESET_MY_SHOW: Scope.fromString(
    "tenant.explore_preset.my.show"
  ),
  TENANT_EXPLORE_PRESET_MY_CREATE: Scope.fromString(
    "tenant.explore_preset.my.create"
  ),
  TENANT_EXPLORE_PRESET_MY_UPDATE: Scope.fromString(
    "tenant.explore_preset.my.update"
  ),
  TENANT_EXPLORE_PRESET_MY_DELETE: Scope.fromString(
    "tenant.explore_preset.my.delete"
  ),
  TENANT_EXPLORE_PRESET_MY_NOTIFY_USE: Scope.fromString(
    "tenant.explore_preset.my.notify_use"
  ),
  TENANT_DASHBOARDS_ALL: Scope.fromString("tenant.dashboards.all"),
  TENANT_DASHBOARDS_SHOW: Scope.fromString("tenant.dashboards.show"),
  TENANT_REPORTS_ALL: Scope.fromString("tenant.reports.all"),
  TENANT_REPORTS_SHOW: Scope.fromString("tenant.reports.show"),
  TENANT_REPORTS_CREATE: Scope.fromString("tenant.reports.create"),
  TENANT_REPORTS_UPDATE: Scope.fromString("tenant.reports.update"),
  TENANT_REPORTS_DELETE: Scope.fromString("tenant.reports.delete"),
  TENANT_REPORTS_ACTIVATE: Scope.fromString("tenant.reports.activate"),
  TENANT_REPORTS_DEACTIVATE: Scope.fromString("tenant.reports.deactivate"),
  TENANT_CURRENCIES_ALL: Scope.fromString("tenant.currencies.all"),
  TENANT_CURRENCIES_SHOW: Scope.fromString("tenant.currencies.show"),
  NORMALIZATION_ALL: Scope.fromString("normalization.all"),
  NORMALIZATION_REPORTINGS_ALL: Scope.fromString(
    "normalization.reportings.all"
  ),
  NORMALIZATION_REPORTINGS_NORMALIZE: Scope.fromString(
    "normalization.reportings.normalize"
  ),
  NORMALIZATION_REPORTINGS_FETCH: Scope.fromString(
    "normalization.reportings.fetch"
  ),
  COLLECT_ALL: Scope.fromString("collect.all"),
  COLLECT_METRICS_ALL: Scope.fromString("collect.metrics.all"),
  COLLECT_METRICS_SHOW: Scope.fromString("collect.metrics.show"),
  COLLECT_DIMENSIONS_ALL: Scope.fromString("collect.dimensions.all"),
  COLLECT_DIMENSIONS_SHOW: Scope.fromString("collect.dimensions.show"),
  COLLECT_REPORTINGS_ALL: Scope.fromString("collect.reportings.all"),
  COLLECT_REPORTINGS_IMPORT: Scope.fromString("collect.reportings.import"),
  COLLECT_REPORTINGS_DELETE: Scope.fromString("collect.reportings.delete"),
  COLLECT_REPORTINGS_BACKUP: Scope.fromString("collect.reportings.backup"),
  COLLECT_REPORTINGS_SHARED_DATASOURCE_ALL: Scope.fromString(
    "collect.reportings.shared_datasource.all"
  ),
  COLLECT_REPORTINGS_SHARED_DATASOURCE_IMPORT: Scope.fromString(
    "collect.reportings.shared_datasource.import"
  ),
  COLLECT_REPORTINGS_SHARED_DATASOURCE_DELETE: Scope.fromString(
    "collect.reportings.shared_datasource.delete"
  ),
  REPORTINGS_ALL: Scope.fromString("reportings.all"),
  REPORTINGS_CUSTOM_DIMENSIONS_ALL: Scope.fromString(
    "reportings.custom_dimensions.all"
  ),
  REPORTINGS_CUSTOM_DIMENSIONS_SHOW: Scope.fromString(
    "reportings.custom_dimensions.show"
  ),
  REPORTINGS_CUSTOM_DIMENSIONS_CREATE: Scope.fromString(
    "reportings.custom_dimensions.create"
  ),
  REPORTINGS_CUSTOM_DIMENSIONS_UPDATE: Scope.fromString(
    "reportings.custom_dimensions.update"
  ),
  REPORTINGS_CUSTOM_DIMENSIONS_DELETE: Scope.fromString(
    "reportings.custom_dimensions.delete"
  ),
  REPORTINGS_CUSTOM_DIMENSIONS_CHECK: Scope.fromString(
    "reportings.custom_dimensions.check"
  ),
  REPORTINGS_CUSTOM_METRICS_ALL: Scope.fromString(
    "reportings.custom_metrics.all"
  ),
  REPORTINGS_CUSTOM_METRICS_SHOW: Scope.fromString(
    "reportings.custom_metrics.show"
  ),
  REPORTINGS_CUSTOM_METRICS_CREATE: Scope.fromString(
    "reportings.custom_metrics.create"
  ),
  REPORTINGS_CUSTOM_METRICS_UPDATE: Scope.fromString(
    "reportings.custom_metrics.update"
  ),
  REPORTINGS_CUSTOM_METRICS_DELETE: Scope.fromString(
    "reportings.custom_metrics.delete"
  ),
  REPORTINGS_CUSTOM_METRICS_CHECK: Scope.fromString(
    "reportings.custom_metrics.check"
  ),
  REPORTINGS_REPORTING_ALL: Scope.fromString("reportings.reporting.all"),
  REPORTINGS_REPORTING_FIELDS_ALL: Scope.fromString(
    "reportings.reporting.fields.all"
  ),
  REPORTINGS_REPORTING_FIELDS_SHOW: Scope.fromString(
    "reportings.reporting.fields.show"
  ),
  REPORTINGS_REPORTING_DATA_ALL: Scope.fromString(
    "reportings.reporting.data.all"
  ),
  REPORTINGS_REPORTING_DATA_SHOW: Scope.fromString(
    "reportings.reporting.data.show"
  ),
  REPORTINGS_REPORTING_GOOGLE_DATA_STUDIO_ALL: Scope.fromString(
    "reportings.reporting.google_data_studio.all"
  ),
  REPORTINGS_REPORTING_GOOGLE_DATA_STUDIO_SHOW: Scope.fromString(
    "reportings.reporting.google_data_studio.show"
  ),
  REPORTINGS_REPORTING_INITIALIZE_FIELDS: Scope.fromString(
    "reportings.reporting.initialize_fields"
  ),
  REPORTINGS_STATISTICS_SHOW: Scope.fromString("reportings.statistics.show"),
  REPORTINGS_CACHE_CLEAR: Scope.fromString("reportings.cache.clear"),
  REPORTINGS_CACHE_WARMUP: Scope.fromString("reportings.cache.warmup"),
  REPORTINGS_FIELD_METAS_ALL: Scope.fromString("reportings.field_metas.all"),
  REPORTINGS_FIELD_METAS_SHOW: Scope.fromString("reportings.field_metas.show"),
  REPORTINGS_FIELD_METAS_UPDATE: Scope.fromString(
    "reportings.field_metas.update"
  ),
  REPORTINGS_OUTGOING_DATASHARE_ALL: Scope.fromString(
    "reportings.outgoing_datashare.all"
  ),
  REPORTINGS_OUTGOING_DATASHARE_SHOW: Scope.fromString(
    "reportings.outgoing_datashare.show"
  ),
  REPORTINGS_OUTGOING_DATASHARE_CREATE: Scope.fromString(
    "reportings.outgoing_datashare.create"
  ),
  REPORTINGS_OUTGOING_DATASHARE_UPDATE: Scope.fromString(
    "reportings.outgoing_datashare.update"
  ),
  REPORTINGS_OUTGOING_DATASHARE_DELETE: Scope.fromString(
    "reportings.outgoing_datashare.delete"
  ),
  EVENTS_ALL: Scope.fromString("events.all"),
  EVENTS_PUBLISH: Scope.fromString("events.publish"),
  EVENTS_LISTEN: Scope.fromString("events.listen"),
  MANAGEMENT_ALL: Scope.fromString("management.all"),
  MANAGEMENT_CHECK: Scope.fromString("management.check"),
  MANAGEMENT_RUN: Scope.fromString("management.run"),
  MANAGEMENT_IMPORT_JOB_SHOW: Scope.fromString("management.import_job.show"),
  MANAGEMENT_IMPORT_JOB_UPDATE: Scope.fromString(
    "management.import_job.update"
  ),
  MANAGEMENT_ERROR_DEBUG_INFO_SHOW: Scope.fromString(
    "management.error_debug_info_show"
  ),
  CURRENCY_ALL: Scope.fromString("currency.all"),
  CURRENCY_OVERVIEW: Scope.fromString("currency.overview"),
  CURRENCY_RUN: Scope.fromString("currency.run"),
};

export type ScopeString =
  | "all"
  | "tenant_independent"
  | "offline"
  | "security.all"
  | "security.clients.all"
  | "security.clients.show"
  | "security.clients.create"
  | "security.clients.update"
  | "security.clients.delete"
  | "security.clients.show_secrets"
  | "security.oauth_clients.all"
  | "security.oauth_clients.show"
  | "security.oauth_clients.create"
  | "security.oauth_clients.update"
  | "security.oauth_clients.delete"
  | "security.oauth_clients.show_secrets"
  | "security.tenants.register"
  | "security.tenants.delete"
  | "security.users.all"
  | "security.users.show"
  | "security.users.create"
  | "security.users.update"
  | "security.users.delete"
  | "security.groups.all"
  | "security.groups.show"
  | "security.groups.create"
  | "security.groups.update"
  | "security.groups.delete"
  | "security.subscriptions.all"
  | "security.subscriptions.show"
  | "security.subscriptions.create"
  | "security.subscriptions.update"
  | "security.subscriptions.delete"
  | "tenant.all"
  | "tenant.credentials.all"
  | "tenant.credentials.show"
  | "tenant.credentials.create"
  | "tenant.credentials.update"
  | "tenant.credentials.delete"
  | "tenant.credentials.show_secrets"
  | "tenant.credentials.refresh_oauth"
  | "tenant.credentials.refresh_status"
  | "tenant.datasources.all"
  | "tenant.datasources.show"
  | "tenant.datasources.create"
  | "tenant.datasources.update"
  | "tenant.datasources.delete"
  | "tenant.datasources.activate"
  | "tenant.datasources.deactivate"
  | "tenant.datasources.meta_templates.show"
  | "tenant.datasource_types.show"
  | "tenant.shared_datasources.all"
  | "tenant.shared_datasources.show"
  | "tenant.shared_datasources.create"
  | "tenant.shared_datasources.update"
  | "tenant.shared_datasources.delete"
  | "tenant.templates.all"
  | "tenant.templates.show"
  | "tenant.templates.create"
  | "tenant.templates.update"
  | "tenant.templates.delete"
  | "tenant.datasource_templates.all"
  | "tenant.datasource_templates.show"
  | "tenant.datasource_templates.create"
  | "tenant.datasource_templates.update"
  | "tenant.datasource_templates.delete"
  | "tenant.datasource_templates.activate"
  | "tenant.datasource_templates.deactivate"
  | "tenant.datasource_templates.notify_data_imported"
  | "tenant.datasource.bucket.getfile"
  | "tenant.datasource.ftpuser.show"
  | "tenant.datasource.ftpuser.regeneratepassword"
  | "tenant.datasource.import_mail.show"
  | "tenant.datasource.import_mail.import"
  | "tenant.datasource.import_mail.regenerate_email"
  | "tenant.datasource.notify_data_available"
  | "tenant.datasource.manual_retry"
  | "tenant.tenants.all"
  | "tenant.tenants.show"
  | "tenant.tenants.create"
  | "tenant.tenants.update"
  | "tenant.tenants.delete"
  | "tenant.tenants.activate"
  | "tenant.tenants.deactivate"
  | "tenant.translations.show"
  | "tenant.audit_logs.show"
  | "tenant.explore_preset.all"
  | "tenant.explore_preset.show"
  | "tenant.explore_preset.create"
  | "tenant.explore_preset.update"
  | "tenant.explore_preset.delete"
  | "tenant.explore_preset.notify_use"
  | "tenant.explore_preset.my.show"
  | "tenant.explore_preset.my.create"
  | "tenant.explore_preset.my.update"
  | "tenant.explore_preset.my.delete"
  | "tenant.explore_preset.my.notify_use"
  | "tenant.dashboards.all"
  | "tenant.dashboards.show"
  | "tenant.reports.all"
  | "tenant.reports.show"
  | "tenant.reports.create"
  | "tenant.reports.update"
  | "tenant.reports.delete"
  | "tenant.reports.activate"
  | "tenant.reports.deactivate"
  | "tenant.currencies.all"
  | "tenant.currencies.show"
  | "normalization.all"
  | "normalization.reportings.all"
  | "normalization.reportings.normalize"
  | "normalization.reportings.fetch"
  | "collect.all"
  | "collect.metrics.all"
  | "collect.metrics.show"
  | "collect.dimensions.all"
  | "collect.dimensions.show"
  | "collect.reportings.all"
  | "collect.reportings.import"
  | "collect.reportings.delete"
  | "collect.reportings.backup"
  | "collect.reportings.shared_datasource.all"
  | "collect.reportings.shared_datasource.import"
  | "collect.reportings.shared_datasource.delete"
  | "reportings.all"
  | "reportings.custom_dimensions.all"
  | "reportings.custom_dimensions.show"
  | "reportings.custom_dimensions.create"
  | "reportings.custom_dimensions.update"
  | "reportings.custom_dimensions.delete"
  | "reportings.custom_dimensions.check"
  | "reportings.custom_metrics.all"
  | "reportings.custom_metrics.show"
  | "reportings.custom_metrics.create"
  | "reportings.custom_metrics.update"
  | "reportings.custom_metrics.delete"
  | "reportings.custom_metrics.check"
  | "reportings.reporting.all"
  | "reportings.reporting.fields.all"
  | "reportings.reporting.fields.show"
  | "reportings.reporting.data.all"
  | "reportings.reporting.data.show"
  | "reportings.reporting.google_data_studio.all"
  | "reportings.reporting.google_data_studio.show"
  | "reportings.reporting.initialize_fields"
  | "reportings.statistics.show"
  | "reportings.cache.clear"
  | "reportings.cache.warmup"
  | "reportings.field_metas.all"
  | "reportings.field_metas.show"
  | "reportings.field_metas.update"
  | "reportings.outgoing_datashare.all"
  | "reportings.outgoing_datashare.show"
  | "reportings.outgoing_datashare.create"
  | "reportings.outgoing_datashare.update"
  | "reportings.outgoing_datashare.delete"
  | "events.all"
  | "events.publish"
  | "events.listen"
  | "management.all"
  | "management.check"
  | "management.run"
  | "management.import_job.show"
  | "management.import_job.update"
  | "management.error_debug_info_show"
  | "currency.all"
  | "currency.overview"
  | "currency.run";
// DO NOT MODIFY THIS COMMENT autogenerated end
