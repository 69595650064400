import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TenantResponse } from "src/app/_models/tenant-response";
import {
  TenantCredentials,
  TenantService,
} from "src/app/_services/api/tenant.service";
import { Translation } from "src/app/_services/translations.service";
import { ToastService } from "src/app/_services/toast.service";
import { DatasourceType } from "src/app/_models/datasource-type";
import { ErrorHandlerService } from "src/app/_services/error-handler.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-oauth",
  template: ``,
  styles: [],
})
export class OauthComponent {
  private static REDIRECT_URL_SESSION_KEY = "oAuthRedirectUrl";
  private static CREDENTIALS_LOCALSTORAGE_KEY = "oAuthCredentials";

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _toastService: ToastService
  ) {
    this._activatedRoute.data.subscribe((data) => {
      const oAuthCredentials: TenantResponse<TenantCredentials> | null =
        data.oAuth;
      if (oAuthCredentials) {
        this.onSuccess(oAuthCredentials);
      }

      this.redirectBack();
    });
  }

  public static redirectOauth(
    datasourceType: DatasourceType,
    tenantService: TenantService,
    errorHandlerService: ErrorHandlerService,
    router: Router
  ): void {
    firstValueFrom(tenantService.getOauthLoginUrl(datasourceType))
      .then((url) => {
        sessionStorage.setItem(
          OauthComponent.REDIRECT_URL_SESSION_KEY,
          router.url
        );
        window.open(url, "_self");
      })
      .catch((error) => {
        errorHandlerService.handle(error);
      });
  }

  private onSuccess(oAuthCredentials: TenantResponse<TenantCredentials>) {
    this._toastService.success(
      new Translation("resolvers.auth_resolver.success.message"),
      new Translation("resolvers.auth_resolver.success.title")
    );
    localStorage.setItem(
      OauthComponent.CREDENTIALS_LOCALSTORAGE_KEY,
      JSON.stringify(oAuthCredentials)
    );
  }

  public static getLastOauthCredentials(): TenantResponse<TenantCredentials> | null {
    return JSON.parse(
      localStorage.getItem(OauthComponent.CREDENTIALS_LOCALSTORAGE_KEY) ??
        "null"
    );
  }

  public static clearLastOauthCredentials(): void {
    localStorage.removeItem(OauthComponent.CREDENTIALS_LOCALSTORAGE_KEY);
  }

  private redirectBack() {
    const redirectUrl = sessionStorage.getItem(
      OauthComponent.REDIRECT_URL_SESSION_KEY
    );
    if (redirectUrl !== null) {
      sessionStorage.removeItem(OauthComponent.REDIRECT_URL_SESSION_KEY);
    }

    if (redirectUrl) {
      this._router.navigate([redirectUrl]);
      return;
    }

    this._toastService.warning(
      new Translation("pages.oauth.toastr.warning.message"),
      new Translation("pages.oauth.toastr.warning.title"),
      undefined,
      { disableTimeOut: true }
    );
    this._router.navigate(["connect-data/datasources"]);
  }
}
