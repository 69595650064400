import { validDefined } from "src/app/_types/defined";

export type GroupedDataGroup<Item, Group> = {
  group: Group;
  items: Item[];
};

export type GroupedData<Item, Group> = GroupedDataGroup<Item, Group>[];
export function isGroupedData<Item extends Object, Group>(
  data: GroupedData<Item, Group> | Item[]
): data is GroupedData<Item, Group> {
  return (
    Array.isArray(data) &&
    data.length > 0 &&
    "group" in validDefined(data[0]) &&
    "items" in validDefined(data[0])
  );
}

export class UtilService {
  private constructor() {}

  static arrayGroupBy<Item extends Object, Group>(
    data: Item[],
    groupBy: string | ((Item) => Group),
    groupKeyCallback: ((Group) => string) | undefined = undefined
  ): GroupedData<Item, Group> {
    const grouped: {
      [key: string]: {
        group: Group;
        items: Item[];
      };
    } = {};
    for (let i = 0; i < data.length; i++) {
      const item: Item = validDefined(data[i]);
      const group: Group =
        typeof groupBy === "string"
          ? item.hasOwnProperty(groupBy)
            ? item[groupBy]
            : null
          : groupBy(item);
      const groupKey = groupKeyCallback
        ? groupKeyCallback(group)
        : JSON.stringify(group);
      if (!grouped.hasOwnProperty(groupKey)) {
        grouped[groupKey] = {
          group: group,
          items: [],
        };
      }
      validDefined(grouped[groupKey]).items.push(item);
    }
    return Object.values(grouped);
  }

  static arrayUniqueStr<T extends string>(list: T[]): T[] {
    const uniqueList: { [key: string]: null } = {};
    list.forEach((item) => {
      uniqueList[item] = null;
    });
    return Object.keys(uniqueList) as T[];
  }
  static arrayToDict<T, K extends string>(
    list: T[],
    key: (T) => K,
    duplicateKey: "error" | "first" | "last" = "error"
  ): { [key: string]: T } {
    const uniqueList: { [key: string]: T } = {};
    list.forEach((item) => {
      if (uniqueList.hasOwnProperty(key(item))) {
        if (duplicateKey === "error") {
          throw new Error(`Duplicate key ${key(item)}`);
        }
        if (duplicateKey === "first") {
          return;
        }
      }
      uniqueList[key(item) as string] = item;
    });
    return uniqueList;
  }

  static arrayUniqueById<T extends { id: string }>(list: T[]): T[] {
    const uniqueList = {};
    list.forEach((item) => {
      uniqueList[item.id] = item;
    });
    return Object.values(uniqueList);
  }

  static arraySameItems<T extends string>(a: T[], b: T[]): boolean {
    if (a.length !== b.length) {
      return false;
    }

    const uniqueList: { [key: string]: null } = {};
    a.forEach((item) => {
      uniqueList[item] = null;
    });

    return b.every((item) => uniqueList.hasOwnProperty(item));
  }

  static arrayAppendAll(array: any[], additionalItems: any[]): void {
    for (let i = 0; i < additionalItems.length; i++) {
      array.push(additionalItems[i]);
    }
  }

  static textSearch<Item>(
    data: Item[],
    searchStr: string | null,
    searchFields: (item: Item) => (string | null | undefined)[]
  ): Item[] {
    searchStr = typeof searchStr === "string" ? searchStr.trim() : null;
    searchStr = searchStr === "" ? null : searchStr;
    if (searchStr === null) {
      return data;
    }
    const searchParts: string[] = searchStr
      .split(" ")
      .map((searchPart) => searchPart.trim())
      .map((searchPart) => searchPart.toLocaleLowerCase())
      .filter((searchPart) => searchPart !== "");
    if (searchParts.length === 0) {
      return data;
    }

    const sort = this.textSearchFilterPriority(searchParts, searchFields);
    return data
      .map((row) => {
        const tmp = sort(row);
        return { sort: tmp[0], sort2: tmp[1], data: row };
      })
      .filter((row) => row.sort >= 0)
      .sort((a, b) =>
        a.sort < b.sort
          ? -1
          : a.sort > b.sort
          ? 1
          : a.sort2.localeCompare(b.sort2)
      )
      .map((row) => row.data);
  }

  /**
   * @deprecated
   * @param search
   * @param searchFields
   */
  static textSearchFilter<Item>(
    search: string | null | string[],
    searchFields: (item: Item) => (string | null | undefined)[]
  ): (data: Item) => boolean {
    const searchParts: string[] = Array.isArray(search)
      ? search
      : (typeof search === "string" ? search : "")
          .trim()
          .toLocaleLowerCase()
          .split(" ")
          .map((searchPart) => searchPart.trim())
          .filter((searchPart) => searchPart !== "");
    if (searchParts.length === 0) {
      return () => true;
    }

    return (item) => {
      const fields = searchFields(item);
      for (let j = 0; j < searchParts.length; j++) {
        const searchPart: string = validDefined(searchParts[j]);
        let found = false;
        for (let i = 0; i < fields.length; i++) {
          const field: string | null | undefined = fields[i];
          if (typeof field !== "string") {
            continue;
          }

          if (field.toLocaleLowerCase().indexOf(searchPart) !== -1) {
            found = true;
            break;
          }
        }

        if (!found) {
          return false;
        }
      }

      return true;
    };
  }

  /**
   * @return number priority for sorting (-1 equals no match, 0 highest priority match, 1..n lower priority match)
   * @param search
   * @param searchFields
   */
  private static textSearchFilterPriority<Item>(
    search: string | null | string[],
    searchFields: (item: Item) => (string | null | undefined)[]
  ): (data: Item) => [number, string] {
    const searchParts: string[] = Array.isArray(search)
      ? search
      : (typeof search === "string" ? search : "")
          .trim()
          .toLocaleLowerCase()
          .split(" ")
          .map((searchPart) => searchPart.trim())
          .filter((searchPart) => searchPart !== "");
    if (searchParts.length === 0) {
      return () => [0, ""];
    }

    return (item) => {
      let foundAtNthFieldSum = 0;
      const fields = searchFields(item);
      let fieldsStr = "";
      for (let j = 0; j < searchParts.length; j++) {
        const searchPart: string = validDefined(searchParts[j]);
        let foundAtNthField: number | null = null;
        for (let i = 0; i < fields.length; i++) {
          const field: string | null | undefined = fields[i];
          if (typeof field !== "string") {
            continue;
          }

          fieldsStr += field;
          if (field.toLocaleLowerCase().indexOf(searchPart) !== -1) {
            foundAtNthField = i;
            break;
          }
        }

        if (foundAtNthField === null) {
          return [-1, ""];
        }
        foundAtNthFieldSum += foundAtNthField / Math.pow(10, j);
      }

      return [foundAtNthFieldSum, fieldsStr.toLocaleLowerCase()];
    };
  }

  static sortBy<T>(
    items: T[],
    ...columns: (string | ((item: T) => string | number | boolean))[]
  ): T[] {
    return items.sort((a: T, b: T): number => {
      for (let i = 0; i < columns.length; i++) {
        const column: string | ((item: T) => string | number | boolean) =
          validDefined(columns[i]);
        const vA = typeof column === "string" ? a[column] : column(a);
        let vB = typeof column === "string" ? b[column] : column(b);
        if (typeof vA === "string") {
          vB = "" + (vB ?? "");
          let cmp = vA.localeCompare(vB);
          if (cmp !== 0) {
            return cmp;
          }
        } else if (typeof vA === "number") {
          vB = parseFloat(vB);
          if (vA < vB) {
            return 1;
          }
          if (vA > vB) {
            return -1;
          }
        } else if (typeof vA === "boolean") {
          vB = !!vB;
          if (vA && !vB) {
            return -1;
          }
          if (!vA && vB) {
            return 1;
          }
        }
      }

      return 0;
    });
  }

  static arrayToMap<T>(
    items: T[],
    key: (c: T) => string
  ): {
    [key: string]: T;
  } {
    const map: { [key: string]: T } = {};
    items.forEach((item) => {
      const k = key(item);
      if (map.hasOwnProperty(k)) {
        console.warn(`Duplicate key ${k}`, map, key, item);
      }
      map[k] = item;
    });
    return map;
  }

  static arrayToListMap<T>(
    items: T[],
    key: (c: T) => string
  ): {
    [key: string]: T[];
  } {
    const map: { [key: string]: T[] } = {};
    items.forEach((item) => {
      const k = key(item);
      if (!map.hasOwnProperty(k)) {
        map[k] = [];
      }
      validDefined(map[k]).push(item);
    });
    return map;
  }
}
