import { Injectable } from "@angular/core";
import { firstValueFrom, Observable, of } from "rxjs";
import { catchError, map, shareReplay, tap } from "rxjs/operators";
import { UtilService } from "../util.service";
import { isUuid, Uuid } from "../../_types/uuid";
import {
  SecurityService,
  SecuritySubscriptionMayWithUserCount,
  SecuritySubscriptionPublic,
  SecuritySubscriptionWithUserCount,
} from "../api/security.service";
import { CachedItemListStoreService } from "../../_store/generic/cached-item-list.store.service";
import { ErrorHandlerService } from "../error-handler.service";

@Injectable({
  providedIn: "root",
})
export class SubscriptionsRepositoryService {
  public readonly $observableDetailed: Observable<
    SecuritySubscriptionWithUserCount[]
  >;
  public readonly $observablePublic: Observable<SecuritySubscriptionPublic[]>;

  private readonly _detailedStore: CachedItemListStoreService<
    SecuritySubscriptionWithUserCount,
    Uuid
  >;
  private readonly _publicStore: CachedItemListStoreService<
    SecuritySubscriptionPublic,
    Uuid
  >;

  constructor(
    private _securityService: SecurityService,
    private _errorHandlerService: ErrorHandlerService
  ) {
    this._detailedStore =
      CachedItemListStoreService.createForUuid<SecuritySubscriptionWithUserCount>();
    this._publicStore =
      CachedItemListStoreService.createForUuid<SecuritySubscriptionPublic>();
    this.$observableDetailed = this._detailedStore.observable
      .pipe(map((list) => UtilService.sortBy(list ?? [], (i) => i.name)))
      .pipe(shareReplay(1));
    this.$observablePublic = this._publicStore.observable
      .pipe(map((list) => UtilService.sortBy(list ?? [], (i) => i.name)))
      .pipe(shareReplay(1));
  }

  public loadDetailedSubscriptions(forceReload: boolean): Observable<boolean> {
    if (!forceReload && this._detailedStore.isLoaded()) {
      return of(false);
    }
    return this._securityService
      .getSubscriptions()
      .pipe(
        tap((subscriptions: SecuritySubscriptionWithUserCount[]) => {
          this._detailedStore.updateValue(subscriptions);
        })
      )
      .pipe(map(() => true))
      .pipe(
        catchError((error) => {
          this._errorHandlerService.handle(error);

          return of(false);
        })
      );
  }
  public loadPublicSubscriptions(forceReload: boolean): Observable<boolean> {
    if (!forceReload && this._publicStore.isLoaded()) {
      return of(false);
    }
    return this._securityService
      .getMySubscriptions()
      .pipe(
        tap((subscriptions: SecuritySubscriptionPublic[]) => {
          this._publicStore.updateValue(subscriptions);
        })
      )
      .pipe(map(() => true))
      .pipe(
        catchError((error) => {
          this._errorHandlerService.handle(error);

          return of(false);
        })
      );
  }

  public async createSubscription(data: {
    name?: string;
    active?: boolean;
    maxTenants?: number;
    maxUsers?: number;
    owners?: string[];
  }): Promise<SecuritySubscriptionMayWithUserCount> {
    const subscription = await firstValueFrom(
      this._securityService.createSubscription(data)
    );
    if (this._detailedStore.isLoaded()) {
      this._detailedStore.addItem(subscription);
    }
    if (this._publicStore.isLoaded()) {
      this._publicStore.addItem(this.publicFromDetailed(subscription));
    }
    return subscription;
  }

  public async removeSubscription(
    subscriptionOrId: Uuid | SecuritySubscriptionMayWithUserCount
  ): Promise<void> {
    const subscriptionId = isUuid(subscriptionOrId)
      ? subscriptionOrId
      : subscriptionOrId.id;
    await firstValueFrom(
      this._securityService.removeSubscription(subscriptionId)
    );

    if (this._detailedStore.isLoaded()) {
      this._detailedStore.removeItemById(subscriptionId);
    }
    if (this._publicStore.isLoaded()) {
      this._publicStore.removeItemById(subscriptionId);
    }
  }

  public async deactivateSubscription(
    subscriptionOrId: Uuid | SecuritySubscriptionMayWithUserCount
  ): Promise<SecuritySubscriptionWithUserCount> {
    const subscriptionId = isUuid(subscriptionOrId)
      ? subscriptionOrId
      : subscriptionOrId.id;
    const subscription = await firstValueFrom(
      this._securityService.deactivateSubscription(subscriptionId)
    );

    if (this._detailedStore.isLoaded()) {
      this._detailedStore.updateItemById(subscription);
    }
    if (this._publicStore.isLoaded()) {
      this._publicStore.updateItemById(this.publicFromDetailed(subscription));
    }

    return subscription;
  }

  public async activateSubscription(
    subscriptionOrId: Uuid | SecuritySubscriptionMayWithUserCount
  ): Promise<SecuritySubscriptionWithUserCount> {
    const subscriptionId = isUuid(subscriptionOrId)
      ? subscriptionOrId
      : subscriptionOrId.id;
    const subscription = await firstValueFrom(
      this._securityService.activateSubscription(subscriptionId)
    );

    if (this._detailedStore.isLoaded()) {
      this._detailedStore.updateItemById(subscription);
    }
    if (this._publicStore.isLoaded()) {
      this._publicStore.updateItemById(this.publicFromDetailed(subscription));
    }

    return subscription;
  }

  public async updateSubscription(
    subscriptionOrId: Uuid | SecuritySubscriptionMayWithUserCount,
    data: {
      name?: string;
      active?: boolean;
      maxTenants?: number;
      maxUsers?: number;
      owners?: string[];
      deactivateAt?: number;
    }
  ): Promise<SecuritySubscriptionWithUserCount> {
    const subscriptionId = isUuid(subscriptionOrId)
      ? subscriptionOrId
      : subscriptionOrId.id;
    const subscription = await firstValueFrom(
      this._securityService.updateSubscription(subscriptionId, data)
    );

    if (this._detailedStore.isLoaded()) {
      this._detailedStore.updateItemById(subscription);
    }
    if (this._publicStore.isLoaded()) {
      this._publicStore.updateItemById(this.publicFromDetailed(subscription));
    }

    return subscription;
  }

  private publicFromDetailed(
    subscription: SecuritySubscriptionMayWithUserCount
  ): SecuritySubscriptionPublic {
    return {
      id: subscription.id,
      name: subscription.name,
      tenants: subscription.tenants,
    };
  }

  public async refreshLoaded(): Promise<void> {
    if (this._detailedStore.isLoaded()) {
      await firstValueFrom(this.loadDetailedSubscriptions(true));
    }
    if (this._publicStore.isLoaded()) {
      await firstValueFrom(this.loadPublicSubscriptions(true));
    }
  }
}
