import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import {
  TenantCredentials,
  TenantService,
} from "src/app/_services/api/tenant.service";
import { catchError } from "rxjs/operators";
import { TenantResponse } from "src/app/_models/tenant-response";
import { ErrorHandlerService } from "src/app/_services/error-handler.service";
import { DatasourceTypeGetStatus } from "src/app/_models/datasource-type";
import {
  DATASOURCE_TYPE_STATUS_ALPHA,
  DATASOURCE_TYPE_STATUS_BETA,
  DATASOURCE_TYPE_STATUS_DEPRECATED,
  DATASOURCE_TYPE_STATUS_DISABLED,
  DATASOURCE_TYPE_STATUS_STABLE,
} from "src/app/_models/datasource-type-status";
import { DebugModeRepositoryService } from "src/app/_services/repository/debug-mode-repository.service";

@Injectable({
  providedIn: "root",
})
export class AuthResolverService {
  constructor(
    private _tenantService: TenantService,
    private _errorHandlerService: ErrorHandlerService,
    private _debugModeRepositoryService: DebugModeRepositoryService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<TenantResponse<TenantCredentials> | null> {
    const oAuthRedirectType: string | any = route.queryParams.type;
    const oAuthRedirectCode: string | any = route.queryParams.code;

    if (typeof oAuthRedirectType !== "string") {
      return of(null);
    }

    if (typeof oAuthRedirectCode !== "string") {
      return of(null);
    }

    const resolvedObservable = this._tenantService
      .createCredentialsFromAuthCode(oAuthRedirectType, oAuthRedirectCode)
      .pipe(
        catchError((error) => {
          this._errorHandlerService.handle(error);

          return of(null);
        })
      );

    const status = DatasourceTypeGetStatus(oAuthRedirectType);
    switch (status) {
      case DATASOURCE_TYPE_STATUS_ALPHA:
        const confirmed = this._debugModeRepositoryService.current
          ? confirm(
              `Redeeming code ${oAuthRedirectCode} for alpha datasource type`
            )
          : false;
        return confirmed ? resolvedObservable : of(null);
      case DATASOURCE_TYPE_STATUS_BETA:
        return resolvedObservable;
      case DATASOURCE_TYPE_STATUS_DEPRECATED:
        console.warn(`Datasource type ${oAuthRedirectType} is deprecated`);
        return resolvedObservable;
      case DATASOURCE_TYPE_STATUS_DISABLED:
        console.warn(
          `Datasource type ${oAuthRedirectType} is disabled`,
          oAuthRedirectCode
        );
        return of(null);
      case DATASOURCE_TYPE_STATUS_STABLE:
        return resolvedObservable;
      default:
        console.warn(
          `Datasource type ${oAuthRedirectType} has unknown status ${status}`,
          oAuthRedirectCode
        );
        return of(null);
    }
  }
}
