import { HttpRequest, HttpRequestMethod } from "./http.request.model";
import { HttpParams } from "@angular/common/http";
import { validDefined } from "src/app/_types/defined";

export class HttpRequestFactory {
  private _query: {
    [key: string]: string | number | boolean | null | undefined;
  };
  private _headers: {
    [key: string]: string | number | boolean | null | undefined;
  };
  private _dynamicHeaders: { [key: string]: () => string | undefined };

  public constructor(
    public readonly method: HttpRequestMethod,
    public readonly url: string,
    public readonly body: any,
    query:
      | { [key: string]: string | number | boolean | null | undefined }
      | null
      | undefined,
    headers:
      | { [key: string]: string | number | boolean | null | undefined }
      | null
      | undefined
  ) {
    this._query = query || {};
    this._headers = headers || {};
    this._dynamicHeaders = {};

    if (this.method === "GET" && this.body) {
      throw new Error("GET request must not contain body data.");
    }
  }

  private static paramValueToString(
    value: string | number | boolean | null | undefined
  ): string {
    if (value === null || typeof value === "undefined") {
      return "";
    } else if (typeof value === "boolean") {
      return value ? "true" : "false";
    } else {
      return "" + value;
    }
  }

  public addQueryParam(key: string, value: string): void {
    this._query[key] = value;
  }

  public addHeader(key: string, value: string): void {
    this._headers[key] = value;
  }

  public addDynamicHeader(
    key: string,
    callback: () => string | undefined
  ): void {
    this._dynamicHeaders[key] = callback;
  }

  public getQuery(): HttpParams | undefined {
    if (Object.keys(this._query).length === 0) {
      return undefined;
    }

    let params = new HttpParams();
    Object.keys(this._query).forEach((key) => {
      params = params.set(
        key,
        HttpRequestFactory.paramValueToString(this._query[key])
      );
    });
    return params;
  }

  public getHeaders(): { [key: string]: string } | undefined {
    if (
      Object.keys(this._headers).length === 0 &&
      Object.keys(this._dynamicHeaders).length === 0
    ) {
      return undefined;
    }

    const params = {};
    Object.keys(this._headers).forEach((key) => {
      const value = this._headers[key];
      if (typeof value !== "undefined") {
        params[key] = HttpRequestFactory.paramValueToString(value);
      }
    });
    Object.keys(this._dynamicHeaders).forEach((key) => {
      const value = validDefined(this._dynamicHeaders[key])();
      if (typeof value !== "undefined") {
        params[key] = HttpRequestFactory.paramValueToString(value);
      }
    });

    return Object.keys(params).length === 0 ? undefined : params;
  }

  public build(): HttpRequest {
    return new HttpRequest(
      this.method,
      this.url,
      this.body,
      this.getQuery() ?? null,
      this.getHeaders() ?? null
    );
  }
}
