<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <app-card [loading]="loading" actionsPosition="center">
        <div class="dc-login-logo-wrapper">
            <img src="./assets/img/iq/intelligence%20qube_rgb.svg"
                 alt="digital-control-logo">
        </div>

        <div class="col-11 offset-1">
            <mat-form-field appearance="outline">
                <mat-label>{{'pages.login.input.username'|trans}}</mat-label>
                <input matInput
                       placeholder="{{'pages.login.input.username'|trans}}"
                       [formGroup]="form"
                       [formControlName]="'username'" autofocus>
            </mat-form-field>
        </div>
        <div class="col-11 offset-1">
            <mat-form-field appearance="outline">
                <mat-label>{{'pages.login.input.password'|trans}}</mat-label>
                <input matInput
                       type="password"
                       placeholder="{{'pages.login.input.password'|trans}}"
                       [formGroup]="form"
                       [formControlName]="'password'">
            </mat-form-field>
        </div>

        <button app-card-footer-action
                mat-raised-button
                type="submit"
                color="primary">
            {{'pages.login.button.submit'|trans}}
        </button>

        <div app-card-footer-last class="back-to-login">
            <a [routerLink]="['../password-reset']" [hidden]="loading" class="text-center">
                {{'pages.login.button.forgot_pwd'|trans}}
            </a>
        </div>
    </app-card>
</form>