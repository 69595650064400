<app-card *ngIf="success">
    <div class="password-reset-success">
        <span class="material-icons check-icon">check_circle</span>
        <h5>{{'pages.pwd_reset.success.div_element.part1'|trans}}</h5>
        <p>{{'pages.pwd_reset.success.div_element.part2'|trans}}</p>
    </div>

    <div app-card-footer-last class="back-to-login">
        <a [routerLink]="['/auth/login']" [hidden]="loading" class="text-center">
            {{'pages.pwd_reset.back'|trans}}
        </a>
    </div>
</app-card>

<form [formGroup]="pwdResetForm" *ngIf="!success" (ngSubmit)="onSubmit()">
    <app-card [headerTitle]="'pages.pwd_reset.header'|trans" actionsPosition="center" [loading]="loading">
        <div class="col-10 offset-1">
            <mat-form-field appearance="outline">
                <mat-label>{{'pages.login.input.username'|trans}}</mat-label>
                <input matInput
                       placeholder="{{'pages.login.input.username'|trans}}"
                       [formGroup]="pwdResetForm"
                       [formControlName]="'username'" autofocus>
            </mat-form-field>
        </div>

        <button app-card-footer-action
                mat-raised-button
                type="submit"
                color="primary">
            {{'pages.pwd_reset.send'|trans}}
        </button>

        <div app-card-footer-last class="back-to-login">
            <a [routerLink]="['/auth/login']" [hidden]="loading" class="text-center">
                {{'pages.pwd_reset.back'|trans}}
            </a>
        </div>
    </app-card>
</form>