export const DATASOURCE_TYPE_STATUS_ALPHA = "alpha";
export const DATASOURCE_TYPE_STATUS_BETA = "beta";
export const DATASOURCE_TYPE_STATUS_DEPRECATED = "deprecated";
export const DATASOURCE_TYPE_STATUS_DISABLED = "disabled";
export const DATASOURCE_TYPE_STATUS_STABLE = "stable";

export type DatasourceTypeStatus =
  | typeof DATASOURCE_TYPE_STATUS_ALPHA
  | typeof DATASOURCE_TYPE_STATUS_BETA
  | typeof DATASOURCE_TYPE_STATUS_DEPRECATED
  | typeof DATASOURCE_TYPE_STATUS_DISABLED
  | typeof DATASOURCE_TYPE_STATUS_STABLE;
