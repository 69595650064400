<mat-toolbar>
    <button mat-icon-button
            class="only-mobile"
            [matMenuTriggerFor]="tenantMenu"
            [title]="($currentTenant | async)?.id">
        <mat-icon color="primary">workspaces</mat-icon>
    </button>
    <ng-container *ngIf="$currentTenant | async as t; else noTenantSelected">
        <button mat-button
                class="only-desktop"
                [matMenuTriggerFor]="tenantMenu"
                [matTooltip]="t.active?t.id:('components.navbar.inactive_tenant'|trans)">
            <mat-icon color="primary" [class]="t.active?'text-primary':'text-warning'">workspaces</mat-icon>
            <span [class]="t.active?'text-primary':'text-warning'">{{ t.name }}</span>
        </button>
    </ng-container>
    <ng-template #noTenantSelected>
        <button mat-button
                class="only-desktop"
                [matMenuTriggerFor]="tenantMenu">
            <mat-icon color="warn">workspaces</mat-icon>
            <span class="text-danger">{{'navbar.select_tenant'|trans}}</span>
        </button>
    </ng-template>
    <mat-menu #tenantMenu>
        <ng-container
                *ngIf="($selectableSubscriptionTenants|async) as selectableSubscriptionTenants; else loadingTenants">
            <ng-container *ngFor="let tenantGroup of ($selectableSubscriptionTenants|async); let i = index">
                <mat-divider *ngIf="i>0"></mat-divider>
                <span mat-menu-item
                      disabled
                      *ngIf="tenantGroup.group">{{tenantGroup.group.name}}</span>
                <button mat-menu-item
                        *ngFor="let tenant of tenantGroup.items"
                        (click)="emitSwitchToTenant(tenant);">
                    {{tenant.name}}
                </button>
            </ng-container>
        </ng-container>
        <ng-template #loadingTenants>
            <mat-spinner diameter="40" color="primary"></mat-spinner>
        </ng-template>
    </mat-menu>

    <button mat-icon-button
            class="only-mobile"
            [matMenuTriggerFor]="profileMenu">
        <mat-icon color="primary">person</mat-icon>
    </button>
    <button mat-button
            class="only-desktop"
            [matMenuTriggerFor]="profileMenu">
        <mat-icon color="primary">person</mat-icon>
        <span class="text-primary">
                        {{ ($user|async)?.userEmail || '-' }}
                    </span>
    </button>
    <mat-menu #profileMenu>
        <button mat-menu-item
                [routerLink]="['user/profile']">
            {{'app.profile'|trans}}
        </button>
        <button mat-menu-item
                (click)="emitLogout();">
            {{'app.logout'|trans}}
        </button>
    </mat-menu>

    <button mat-icon-button
            class="only-mobile"
            [matMenuTriggerFor]="langMenu">
        <mat-icon color="primary">language</mat-icon>
    </button>
    <button mat-button
            class="only-desktop"
            [matMenuTriggerFor]="langMenu">
        <mat-icon color="primary">language</mat-icon>
        <span class="text-primary">{{ ($userLanguage|async) || '-' }}</span>
    </button>
    <mat-menu #langMenu>
        <button mat-menu-item
                (click)="emitChangeLanguage('de');">
            DE
        </button>
        <button mat-menu-item
                (click)="emitChangeLanguage('en');">
            EN
        </button>
    </mat-menu>

    <button mat-icon-button
            (click)="toggleDarkMode()">
        <mat-icon color="primary">{{($darkMode|async) ? 'dark_mode' : 'light_mode'}}</mat-icon>
    </button>

    <button mat-icon-button *appIsAdmin
            (click)="toggleDebugMode()">
        <mat-icon [color]="($debugMode|async) ? 'warn':'primary'">bug_report</mat-icon>
    </button>

    <button mat-icon-button (click)="emitToggleSidebar()" class="only-mobile">
        <mat-icon color="primary">menu</mat-icon>
    </button>
</mat-toolbar>