import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { ToastService } from "src/app/_services/toast.service";
import { Translation } from "src/app/_services/translations.service";
import { SupportService } from "src/app/_services/support.service";
import { filter, takeUntil } from "rxjs/operators";
import { DarkModeRepositoryService } from "src/app/_services/repository/dark-mode-repository.service";
import { Observable, Subject } from "rxjs";
import { validDefined } from "./_types/defined";
import { DebugModeRepositoryService } from "./_services/repository/debug-mode-repository.service";

@Component({
  selector: "app-my-app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  private _$destroyed = new Subject<void>();
  public loading = false;
  public supportAvailable: boolean = false;
  public $darkMode: Observable<boolean | null>;
  public $debugMode: Observable<boolean | null>;

  constructor(
    private _toastService: ToastService,
    private _supportService: SupportService,
    private _darkModeRepositoryService: DarkModeRepositoryService,
    debugModeRepositoryService: DebugModeRepositoryService,
    private router: Router
  ) {
    this.$darkMode = this._darkModeRepositoryService.observable;
    this.$debugMode = debugModeRepositoryService.observable;

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const body = validDefined(document.getElementsByTagName("body")[0]);
        const modalBackdrop =
          document.getElementsByClassName("modal-backdrop")[0];
        if (body.classList.contains("modal-open")) {
          body.classList.remove("modal-open");
          modalBackdrop?.remove();
        }
      });

    this.supportAvailable = this._supportService.isAvailable();

    this.$darkMode.pipe(takeUntil(this._$destroyed)).subscribe((darkMode) => {
      const themeClass: string = "theme-alternate";
      const rootEl = document.documentElement;
      if (darkMode) {
        if (!rootEl.classList.contains(themeClass)) {
          rootEl.classList.add(themeClass);
        }
      } else {
        if (rootEl.classList.contains(themeClass)) {
          rootEl.classList.remove(themeClass);
        }
      }
    });

    this.$debugMode.pipe(takeUntil(this._$destroyed)).subscribe((debugMode) => {
      const themeClass: string = "theme-debug";
      const rootEl = document.documentElement;
      if (debugMode) {
        if (!rootEl.classList.contains(themeClass)) {
          rootEl.classList.add(themeClass);
        }
      } else {
        if (rootEl.classList.contains(themeClass)) {
          rootEl.classList.remove(themeClass);
        }
      }
    });
  }

  ngOnDestroy() {
    this._$destroyed.next();
    this._$destroyed.complete();
  }

  public openSupport(): void {
    this._supportService.open().catch(() => {
      this._toastService.warning(
        new Translation("components.app.support_not_available.title")
      );
    });
  }
}
