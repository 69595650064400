import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { AuthService } from "src/app/_services/auth.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CurrentTenantService } from "src/app/_services/current-tenant.service";
import { AuthUserReadonlyRepositoryService } from "src/app/_services/repository/auth-user-readonly-repository.service";
import { isTenantId } from "../_types/tenant-id";

@Injectable({ providedIn: "root" })
export class NoAuthGuardService implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _currentTenantService: CurrentTenantService,
    private _authUserRepositoryService: AuthUserReadonlyRepositoryService,
    private _router: Router
  ) {}

  private _canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const tenantId = route.queryParamMap.get("tenant_id");
    return (
      isTenantId(tenantId)
        ? this._authService.initLoginAndSwitchTenant(tenantId)
        : this._authService.initLogin()
    ).pipe(
      map((user): true | UrlTree => {
        if (user === null) {
          return true;
        }

        return AuthService.getAfterSuccessfulLoginUrl(
          this._router,
          state.root,
          this._currentTenantService,
          () => this._authUserRepositoryService.currentAuthUser
        );
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this._canActivate(route, state);
  }
}
