export function isNotNull<T>(value: T | null): value is T {
  return value !== null;
}

export function assertNotNull<T>(value: T | null): asserts value is T {
  if (!isNotNull<T>(value)) {
    throw new Error("Expected defined not null, got null.");
  }
}

export function validNotNull<T>(value: T | null): T {
  assertNotNull<T>(value);
  return value;
}
