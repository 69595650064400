import { assertUuid } from "./uuid";

export type TenantId = string & { __iq_type_tenant_id: never };

export function isTenantId(
  value: TenantId | string | unknown
): value is TenantId {
  return (
    typeof value === "string" &&
    // forced to be uuid v4
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
      value
    )
  );
}

export function assertTenantId(
  value: TenantId | string | unknown
): asserts value is TenantId {
  if (!isTenantId(value)) {
    assertUuid(value);
    throw new Error("Expected tenant-id string, got '" + value + "'.");
  }
}

export function validTenantId(value: TenantId | string | unknown): TenantId {
  assertTenantId(value);
  return value;
}
