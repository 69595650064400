import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Tenant } from "./api/tenant.service";
import { filter, map, shareReplay } from "rxjs/operators";
import { isTenantId, TenantId, validTenantId } from "../_types/tenant-id";
import { isStringNotEmpty, StringNotEmpty } from "../_types/string-not-empty";
import { isNotNull } from "../_types/not-null";

@Injectable({
  providedIn: "root",
})
export class CurrentTenantService {
  private static readonly STORAGE_KEY = "lastTenant";
  private static readonly STORAGE_KEY_TENANT_NAME = "selectedTenantName";
  private static readonly STORAGE_KEY_TENANT_DEFAULT_CURRENCY =
    "selectedTenantDefaultCurrency";
  private _currentTenant: BehaviorSubject<null | {
    id: TenantId;
    name: string;
    defaultCurrency: string | null;
  }> = new BehaviorSubject<null | {
    id: TenantId;
    name: string;
    defaultCurrency: string | null;
  }>(null);

  constructor() {
    this._updateValue(
      this._storageGet(),
      this._storageGetName(),
      this._storageGetDefaultCurrency()
    );
  }

  private _storageGet(): TenantId | null {
    const tenantId = localStorage.getItem(CurrentTenantService.STORAGE_KEY);
    return isTenantId(tenantId) ? tenantId : null;
  }

  private _storageGetName(): StringNotEmpty | null {
    const name = localStorage.getItem(
      CurrentTenantService.STORAGE_KEY_TENANT_NAME
    );

    return isStringNotEmpty(name) ? name : null;
  }

  private _storageGetDefaultCurrency(): string | null {
    return (
      localStorage.getItem(
        CurrentTenantService.STORAGE_KEY_TENANT_DEFAULT_CURRENCY
      ) || null
    );
  }

  private _storageSet(tenantId: TenantId | null): void {
    if (tenantId === null) {
      localStorage.removeItem(CurrentTenantService.STORAGE_KEY);
      localStorage.removeItem(CurrentTenantService.STORAGE_KEY_TENANT_NAME);
      return;
    }
    localStorage.setItem(CurrentTenantService.STORAGE_KEY, tenantId);
  }

  private _storageSetName(tenantName: StringNotEmpty | null): void {
    if (tenantName === null) {
      localStorage.removeItem(CurrentTenantService.STORAGE_KEY_TENANT_NAME);
      return;
    }
    localStorage.setItem(
      CurrentTenantService.STORAGE_KEY_TENANT_NAME,
      tenantName
    );
  }

  private _storageSetDefaultCurrency(
    tenantDefaultCurrency: string | null
  ): void {
    if (tenantDefaultCurrency === null) {
      localStorage.removeItem(
        CurrentTenantService.STORAGE_KEY_TENANT_DEFAULT_CURRENCY
      );
      return;
    }
    localStorage.setItem(
      CurrentTenantService.STORAGE_KEY_TENANT_DEFAULT_CURRENCY,
      tenantDefaultCurrency
    );
  }

  private _updateValue(
    tenantId: TenantId | null,
    tenantName: StringNotEmpty | null,
    tenantDefaultCurrency: string | null
  ): void {
    this._storageSet(tenantId);
    this._storageSetName(tenantName);
    this._storageSetDefaultCurrency(tenantDefaultCurrency);
    this._currentTenant.next(
      tenantId
        ? {
            id: tenantId,
            name: tenantName ?? tenantId,
            defaultCurrency: tenantDefaultCurrency,
          }
        : null
    );
  }

  public updateTenant(tenant: Tenant | null): void {
    this._updateValue(
      tenant ? tenant.id : null,
      tenant ? tenant.name : null,
      tenant ? tenant.defaultCurrency : null
    );
  }

  public get currentTenantId(): TenantId | null {
    return this._currentTenant.getValue()?.id || null;
  }

  public get currentValidTenantId(): TenantId {
    return validTenantId(this._currentTenant.getValue()?.id);
  }

  public get currentTenantName(): string | null {
    return this._currentTenant.getValue()?.name || null;
  }

  public get currentTenantDefaultCurrency(): string | null {
    return this._currentTenant.getValue()?.defaultCurrency || null;
  }

  public get observable(): Observable<{
    id: TenantId;
    name: string;
    defaultCurrency: string | null;
  } | null> {
    return this._currentTenant.asObservable();
  }

  public get observableId(): Observable<TenantId | null> {
    return this.observable.pipe(map((tenant) => (tenant ? tenant.id : null)));
  }

  public get observableValidId(): Observable<TenantId> {
    return this.observableId.pipe(filter(isNotNull)).pipe(shareReplay(1));
  }
}
